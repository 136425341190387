export const impressions: string[] = [
  "普通の夢",
  "良い夢",
  "悪夢",
  "明晰夢"
]

export const dream_types: string[] = [
  "就寝",
  "昼寝",
  "うたた寝"
]